.hedgerowListContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 0px;
}

.topDivider,
.bottomDivider {
  border: none;
  height: 1px;
  margin: 0px 0px 8px 0px;
  background-color: rgba(0, 0, 0, 0.12);
}

.bottomDivider {
  margin-top: 8px;
}

