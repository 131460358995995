.container,
.containerSelected {
  display: grid; 
  grid-template-columns: min-content auto min-content;
  gap: 8px 8px;
  grid-template-areas:
    "icon title actions"
    ". content content"
    ". footer footer";
  padding: 8px;
  border: transparent 1px solid;
  border-radius: 4px;
}

.containerSelected {
  background-color: rgb(251, 176, 59, 0.1);
  border: #fbb03b 1px solid;
}

.content {
  grid-area: content;
}

.footer {
  margin-top: 4px;
  font-size: 12px;
  grid-area: footer;
  color: #d32f2f;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
}

.icon {
  justify-self: start;
  align-self: center;
  grid-area: icon;
  margin-top: 3px;
}

.title {
  font-weight: 600;
  overflow-x: hidden;
  justify-self: start;
  align-self: center;
  grid-area: title;
  width: 100%;
}

.actions {
  justify-self: end;
  align-self: center;
  grid-area: actions;
  white-space: nowrap;
  column-gap: 2px;
}
.actions button {
  border: 1px solid #E1E6EF;
}

.draw {
  padding-top: 12px;
}

.species {
  margin-top: 4px;
}

.area {
  margin-top: 8px;
}

.lastUpdated {
  margin-top: 8px;
  color: #6a6e79;
}