.buttonIcon {
    margin-right: 6px;
}

.buttonIconSquare {
    color: #3F444D !important;
    background-color: #F1F3F9 !important;
    border-radius: 8px !important;
}

.buttonIconMap {
    border-radius: 8px !important;
    height: 40px !important;
    width: 40px !important;
    margin: 4px !important;
}

/* PRIMARY (GREEN) Button */
.buttonRegularPrimary {
    font-weight: bold !important;
    text-transform: none !important;
    background-color: #008558 !important;
    color: white !important;
}
.buttonRegularPrimary:hover {
    background-color:#006B47 !important;
    color: white !important;
    /*box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.71);*/
}
.buttonRegularPrimary[aria-pressed="true"] {
    background-color:#008558 !important;
    color: white !important;
}

/* GREY Button */
.buttonRegularGrey {
    font-weight: bold !important;
    text-transform: none !important;
    background-color: #F1F3F9 !important;
    color: black !important;
}
.buttonRegularGrey:hover {
    background-color: #6c707a !important;
    color: white !important;
    /*
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.71);*/
}
.buttonRegularGrey[aria-pressed="true"] {
    background-color:#008558 !important;
    color: white !important;
}

/* RED Button */
.buttonRegularRed {
    font-weight: bold !important;
    text-transform: none !important;
    background-color: #d32f2f !important;
    color: white !important;
}
.buttonRegularRed:hover {
    background-color: #ee7676 !important;
    color: white !important;
}
.buttonRegularRed[aria-pressed="true"] {
    background-color:#d32f2f !important;
    color: white !important;
}

/* INVALID Button */
.buttonRegularInvalid {
    font-weight: bold !important;
    text-transform: none !important;
    background-color: #fbb03b !important;
    border: #f39600 1px solid !important;
    color: black !important;
}
.buttonRegularInvalid:hover {
    background-color: #f39600 !important;
    border: #f39600 1px solid  !important;
    color: black !important;

}
.buttonRegularInvalid[aria-pressed="true"] {
    background-color:#fbb03b !important;
    border: #f39600 1px solid  !important;
    color: black !important;

}


/*
.buttonRegular:active,
.buttonRegular[aria-pressed="true"]
.buttonRegularNoHover:active,
.buttonRegularNoHover[aria-pressed="true"] {
    background-color:#008558 !important;
    color: white !important;
}*/




/* GLOBAL DIALOG STYLES */
.dialogTitle {
    font-weight: bold !important;
}

.dialogContent {
    margin-top: 6px;
    min-width: none;
}

.dialogActionsAlignRight {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}

/* Styles for a simple label and input control */
.inputControl {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}

.inputControlHidden {
    display: none;
}

.inputControl label {
    font-weight: bold !important;
    margin-bottom: 4px;
}

.inputControl .labelHelp {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between
}
.inputControl .labelHelp svg {
    color: #008558;
    cursor: pointer;
}

.inputControl input {
    margin: 0px 0px;
    height: 10px;
}

.buttonLarge {
    width: 200px;
    height: 120px;
    background-color: #F1F3F9  !important;
    color: #008558 !important;
    cursor: pointer;
    border-radius: 4px  !important;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;
    row-gap: 16px;
}

.buttonLarge label {
    cursor: inherit;
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}

.buttonLarge svg{
    height: 24px  !important;
    width: 24px  !important;
    color: black;
    padding: 10px;
    background-color: #DFF6EE  !important;
    border-radius: 50%  !important;

    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
}

.buttonLarge:hover {
    background-color: #DFF6EE  !important;
}

.buttonLarge[aria-selected="true"] {
    background-color: #65406D  !important;
}

.buttonLarge[aria-selected="true"] label {
    color:#EFDEF2 !important;
}

.buttonLarge[aria-selected="true"] svg {
    background-color: #EFDEF2  !important;
}

.buttonLarge[aria-disabled="true"] {
    background-color: #F1F3F9  !important;
}

.buttonLarge[aria-disabled="true"] label {
    color: #3F444D;
}

.buttonLarge[aria-disabled="true"] svg {
    background-color: #F1F3F9  !important;
}

.buttonLargeGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal;
    column-gap: 16px;
}

.simpleErrorLabel {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-left: 14px;
    margin-right: 14px;
    color: #d32f2f;
    margin-top: 3px;
    text-align: left;
}

.verticalStack {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

/* Use to remove duplicate line when using MUIDivider */
.verticalDivider {
    background-color: transparent !important;
}
