.dataLayersContainer {
    margin: 16px 10px !important;
}

.dataLayersLabel {
    position: absolute;
    left: 60px!important;
    top: 16px;
    font-weight: bold;
    font-size: 20px;
}

.dataLayersImage {
    position: absolute;
    left: 16px;
    top: 15px;
}

.dataLayersMenuItem {
    font-size: 10px!important
}

.rightMapButtonsContainer {
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 4px;
    margin-right: 4px;
}

.leftMapButtonsContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    margin-top: 4px;
    margin-left: 4px;
}

.pageContainer {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.sideContainer {
    width: 360px;
    background-color: white;

    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;
}

.logoContainer {
    padding: 8px 12px;
    background: white;
}

.plotContainer {
    padding: 0px 16px !important;
    background-color: white;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 16px;
}

.plotIcon {
    width: 36px !important;
    height: 36px !important;

    flex-grow: 0;
    flex-shrink: 0;
}

.plotLabel {
    font-weight: bold;
    font-size: 20px;
    top: 10px;

    flex-grow: 1;
    flex-shrink: 1;
}

.plotNewButton {
    text-transform: none !important;

    flex-grow: 0;
    flex-shrink: 0;
}

.hedgerowsContainer {
    padding: 0px 16px !important;
    background-color: white;

    display: flex;
    flex-direction: column;
    row-gap: 10px;;
}

.mapContainer {
    background-color: black;
    position: relative;

    flex-grow: 1;
    flex-shrink: 1;
}

.searchInput {
    background-color: #F1F3F9;
    top: 8px;
    right: 8px;
    position: absolute;
    width: 240px;
    height: 24px;
    border-radius: 8px;
    border: none;
    padding: 8px 8px 8px 16px;
    font-size: 16px;
}
.searchInput:focus {
    outline: none;
}

.mapDrawModeButton {
    background-color: #fbb03b;
    color: white !important;
    border-radius: 4px !important;
    height: 50px !important;
    margin: 4px !important;
    padding: 0px 8px !important;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;

    overflow: hidden;
text-overflow: ellipsis;
}
