.mapboxgl-ctrl-top-left,
.maplibregl-ctrl-top-left {
    top: 0 !important;
    left: 8px !important;
}

/* Override mapbox drawing toolbar */
.mapboxgl-ctrl-group,
.maplibregl-ctrl-group {
    border-radius: 0px !important;
    background: transparent !important;
    box-shadow: none !important;
}

/* Override mapbox drawing toolbar buttons */
.mapbox-gl-draw_ctrl-draw-btn {
    background-color: #F1F3F9 !important;
    border-radius: 4px !important;
    height: 42px !important;
    width: 42px !important;
    margin-bottom: 10px !important;
}

/* Override mapbox drawing toolbar buttons (ACTIVE) */
.mapbox-gl-draw_ctrl-draw-btn.active {
    background-color:#6ee7bf !important;
}

/* Hide the bottom right map info on map canvas */
.maplibregl-ctrl-bottom-right.mapboxgl-ctrl-bottom-right {
    display: none;
}