.contentContainer {
  display: flex;
  flex-direction: row;
  column-gap: 14px;
}

.contentInnerContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
}