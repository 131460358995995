
.container {
    background-color:#6ee7bf; 
    position: absolute;
    width: 430px;
    bottom: 10px;
    right: 10px;
    padding: 20px;
    filter: drop-shadow(4px 4px 10px #000000);
}

.details {
    display: flex;
    flex-direction: row;
}

.detailsList {
    padding-left: 0px;
    list-style-type: none;
    padding-right: 20px;
}