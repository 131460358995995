/* These class are here as I have no idea how to make this work in muiStyleOverrides.ts  */

/* When MUI control in focus */
.Mui-focused fieldset {
  border: 1px rgba(0, 0, 0, 0.23) solid !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.23);
}

/* Default MUI input 
.MuiOutlinedInput-root fieldset {
  border: 1px rgba(0, 0, 0, 0.23) solid !important;
} */
.MuiOutlinedInput-input {
  padding: 10px 14px !important;
  min-height: 28px !important;
}

/* Multi-line MUI input */
.MuiInputBase-inputMultiline {
  padding: 0px !important;
}

/* Select (dropdown) */
.MuiSelect-outlined {
  padding-top: 13px !important;
  padding-bottom: 7px !important;
}

/* Chip */
.MuiChip-filled {
  background-color: #dff6ee  !important;
}

/* Autocomplete */
.MuiAutocomplete-inputRoot {
  padding: 0px 9px !important;
}
.MuiAutocomplete-option[aria-selected='true'] {
  background-color: #dff6ee !important;
}

/* Menu / Select item */
.MuiMenuItem-root[aria-selected='true'] {
  background-color: #dff6ee !important;
}

/* Checkbox */
.MuiCheckbox-root.Mui-checked {
  color: #008558 !important;
}
.MuiCheckbox-root {
  height: 26px !important;
}

/* Toggle Button */
.MuiToggleButton-root {
  line-height: normal !important;
}
.MuiToggleButton-root[aria-pressed="true"] {
  background-color:#008558 !important;
  color: white !important;
}